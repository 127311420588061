/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 */
 body::-webkit-scrollbar {
  display: none;

}

.workimage {
  width:889px;
  height:488px
  }

  .workimage1{
    width:265px;
    height:450px
  }
  .web{
    position: absolute;
    z-index: 1;
    
    
  }

  .web:hover{
    position: absolute;
    z-index: 99;
    transition-timing-function: ease-in-out;
    transition-duration: 300ms;
    transition-delay: 150ms;
    transform: translateX(1.5rem);
    transform: -(translateY(0.25rem));
    transform: scale(1.1);
    
    
  }

  

  .phone{
    position: relative;
    z-index: 9;
    margin-left: 50px;
    top: 70px;
    left:800px;
  }


  .phone:hover{
    
    transition-timing-function: ease-in-out;
    transition-duration: 300ms;
    transition-delay: 150ms;
    transform: translateX(1.5rem);
    transform: -(translateY(0.25rem));
    transform: scale(1.1);
    
    
  }
@media screen  and (max-width : 768px)
  {
    .workimage {
      max-width: 90%;
      height: auto
      }

      .workimage1 {
        max-width: 30%;
        height: auto
      }

      .web {
        position: absolute;
        z-index: 1;
       
      }
    
      .phone{
        position: relative;
        z-index: 9;
        top: 30px;
        left: 160px;
      }
  }

@tailwind base;
@tailwind components;
@tailwind utilities;